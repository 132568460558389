<template>
    <modal ref="modalEdicionStock" :titulo="titulo" tamano="" :cargando="cargando" @guardar="editar_cedis_productos">
        <ValidationObserver ref="validator">
            <div class="row justify-center my-3">
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required|max_value:99999" name="stock mínimo">
                        <label class="pl-3 text-muted2 f-15"> Stock Mínimo </label>
                        <el-input v-model="model.stock_minimo_cedis" size="small" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required|max_value:99999" name="stock mínimo vendedor">
                        <label class="pl-3 text-muted2 f-15"> Stock Mínimo vendedor </label>
                        <el-input v-model="model.stock_minimo_tienda" size="small" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col-auto d-flex align-items-end pb-2">
                    <el-checkbox v-model="model.venta_sin_stock" class="check-orange" :true-label="1" :false-label="0">
                        Permite vender el producto sin stock
                    </el-checkbox>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'

import Productos from '~/services/cedis/cedis_productos'

export default {
    data(){
        return{
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
            titulo: '',
            cargando: false,
            stock: '',
            permite: false,
            model:{
                stock_minimo_cedis: null,
                stock_minimo_tienda: null,
                venta_sin_stock:0,
                id_cedis: null,
                id_producto: null
            }
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            id_cedis:'cedis/id_cedis'

        })
    },
    methods: {
        toggle(info, producto){
            this.titulo = `Edición formato de stock del ${producto.nombre} -${producto.presentacion}`
            this.model.stock_minimo_cedis = info.stock_minimo_cedis
            this.model.stock_minimo_tienda = info.stock_minimo_tienda
            this.model.venta_sin_stock = info.venta_sin_stock
            this.model.id_producto = this.id_producto
            this.model.id_cedis= this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis
            this.$refs.modalEdicionStock.toggle();
        },
        async editar_cedis_productos(){
            try {
                const valid = await this.$refs.validator.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'Campos obligaorios', 'warning')
                    return false
                }
                this.cargando = true
                const {data} = await Productos.actualizarCedisProductosStock(this.model)
                this.notificacion('Mensaje', 'Datos actualizados', 'success')
                this.$emit('actualizar', data.cedisProducto)
                this.cargando = false
                this.$refs.modalEdicionStock.toggle();

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
